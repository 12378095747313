<template>
  <div class="background">
    <v-toolbar
      flat
      dense
      class="transparent"
      style="padding-top: env(safe-area-inset-top)"
    >
      <v-btn
        style="z-index: 1; width: 37px; height: 37px; margin-left: -4px"
        class="image-action"
        top
        absolute
        icon
        small
        @click.stop="$router.back()"
        ><img src="@/assets/img/arrow-left.svg" style="margin-left: -4px"
      /></v-btn>
      <v-spacer></v-spacer>
      <v-btn
        style="z-index: 1; width: 37px; height: 37px"
        class="image-action mt-5 mr-2"
        small
        icon
        @click.stop="share()"
        ><img src="@/assets/img/share.svg" style="margin-left: -4px"
      /></v-btn>
      <v-btn
        icon
        width="37"
        height="37"
        style="z-index: 11; margin-right: -1px"
        class="mt-5"
        @click="showLocationDialog = true"
      >
        <v-img
          width="37"
          height="37"
          :src="require('@/assets/img/curved_arrow.svg')"
        ></v-img>
      </v-btn>
    </v-toolbar>
    <v-container class="pt-1 px-4 pb-0 mb-n1 mt-3">
      <v-card
        v-if="ticket"
        class="ticket"
        style="
          margin-top: env(safe-area-inset-top);
          margin-bottom: env(safe-area-inset-bottom);
        "
      >
        <!-- Details and dates -->
        <div>
          <v-img
            eager
            class="image"
            height="217px"
            :src="highResReserveoutImage"
            :lazy-src="ticket.imageUrl"
          >
            <div class="favorite">
              <v-btn
                icon
                large
                @click.stop="
                  toggleFavorite({
                    pocoId: parentId,
                    placeId: ticket.placeId,
                    ticketid: ticket.id,
                    type: tickettype,
                  })
                "
                v-if="
                  $store.state.profile.data &&
                  $store.state.session.user &&
                  !$store.state.isMiKY
                "
              >
                <v-icon
                  :color="
                    find($store.state.profile.data.favorites, (fav) => {
                      return fav === ticket.id || fav.ticketid === ticket.id;
                    })
                      ? 'red'
                      : 'white'
                  "
                >
                  {{
                    find($store.state.profile.data.favorites, (fav) => {
                      return fav === ticket.id || fav.ticketid === ticket.id;
                    })
                      ? "mdi-heart"
                      : "mdi-heart-outline"
                  }}
                </v-icon>
              </v-btn>
            </div>
          </v-img>

          <div class="mx-2 mt-10 mb-1 HeadingDarkH3-22Center">
            {{ ticket.name }}
          </div>
          <v-row>
            <v-col class="col-2 py-0">
              <v-btn class="ml-3 mt-3" icon @click="showLocationDialog = true">
                <img src="@/assets/img/location.svg" />
              </v-btn>
            </v-col>
            <open-in-maps
              :lat="ticket.location.lat"
              :lng="ticket.location.lng"
              @cancel="showLocationDialog = false"
            ></open-in-maps>
            <v-col class="col-9 pa-0">
              <v-row>
                <v-col class="pb-0 pl-4" style="padding-top: 10px">
                  <div class="Heading-Dark-H5-16-Left-Align">
                    {{ ticket.placeName }}
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="ticket.venueAddress">
                <v-col class="pt-0 pl-4">
                  <div
                    style="color: #606060; vertical-align: middle"
                    class="Text-Dark-Text-3---14-Left-Align"
                  >
                    {{ ticket.venueAddress.streetName }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="mx-4">
            <v-divider class="thin-divider" color="#d0c9d6" />
            <v-card-text
              style="padding: 5px 0 9px 0"
              class="Heading-Dark-H5-16-Left-Align"
            >
              {{ ticket.summary }}
            </v-card-text>
            <v-divider class="thin-divider" color="#d0c9d6" />
          </div>
          <div class="mx-4">
            <v-card-text
              style="padding: 14px 0 15px 0"
              class="Text-Dark-Text-2---16-Left-Align"
            >
              <v-container class="v-html pa-0" v-html="ticket.description" />
            </v-card-text>
          </div>
          <template>
            <v-row>
              <v-col class="col-2 pb-0" />
              <v-col class="col-4 pb-0">
                <span
                  class="ml-n4 Heading-Dark-H4-18-Left-Align"
                  @click="isCalendar = false"
                >
                  {{ $t("tickets.nextDates") }}
                </span>
              </v-col>
              <v-col class="col-6 pb-0">
                <span
                  style="margin-left: 25px"
                  class="Heading-Dark-H4-18-Left-Align"
                  @click="isCalendar = true"
                >
                  {{ $t("tickets.calendar") }}
                </span>
              </v-col>
            </v-row>
            <div class="tabslider" v-bind:class="{ calendar: isCalendar }" />
            <v-row class="ml-0 mb-4" v-if="!isCalendar">
              <v-col
                class="nextdates"
                v-for="(date, index) in ticket.dates.slice(0, 3)"
                :key="`nextdates-${index}`"
                @click="nextdatesitemselected = index"
              >
                <v-row class="mx-0">
                  <v-col class="pa-0">
                    <span
                      class="Heading-Dark-H5-16-Center nextdatesitem"
                      v-bind:class="{
                        gradient: nextdatesitemselected === index,
                      }"
                      style="
                        display: flex;
                        justify-content: center;
                        margin-top: 2px;
                      "
                    >
                      {{ getWeekday(date) }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pa-0">
                    <span
                      class="Heading-Dark-H5-16-Center"
                      style="
                        display: flex;
                        justify-content: center;
                        margin-top: 2px;
                      "
                    >
                      {{ getDay(date) }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pa-0">
                    <span
                      class="Text-Dark-Text-2---16-Center"
                      style="
                        display: flex;
                        justify-content: center;
                        margin-top: 2px;
                      "
                    >
                      {{ getMonth(date) }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-for="index in ticket.dates.length < 3
                  ? 3 - ticket.dates.length
                  : 0"
                :key="`nextdates-filler-${index}`"
              />
              <v-col class="col-2" />
            </v-row>
            <date-picker
              v-if="isCalendar && !$vuetify.theme.dark"
              class="datepicker"
              is-expanded
              v-model="pickedDate"
              :available-dates="availableDates"
            />
            <date-picker
              v-if="isCalendar && $vuetify.theme.dark"
              is-dark
              class="darkdatepicker"
              is-expanded
              v-model="pickedDate"
              :available-dates="availableDates"
            />
            <span class="mx-4 Heading-Dark-H4-18-Left-Align">
              {{ $t("tickets.time") }}
            </span>
            <v-layout class="mb-8" style="overflow: hidden; overflow-x: auto">
              <v-flex v-for="(time, index) in times" :key="`time-${index}`">
                <v-btn
                  class="time Heading-Dark-H6-14-Center"
                  @click="timeselected = index"
                  v-bind:class="{ gradient: timeselected === index }"
                  >{{ time }}</v-btn
                ></v-flex
              >
              <v-flex
                v-for="index in times.length < 4 ? 4 - times.length : 0"
                :key="`time-filler-${index}`"
                style="
                  margin: 6px -7px 4px 16px;
                  height: 32px !important;
                  width: 73px;
                "
              />
            </v-layout>
          </template>
          <div class="ticket-tear-line"></div>
          <v-row class="mt-4">
            <v-col
              class="number-of-tickets col-6 Heading-Color-H5-16-Left-Align"
            >
              {{ $t("tickets.numberOfTickets") }}
            </v-col>
            <v-col class="col-2">
              <v-btn
                class="number-of-tickets-button"
                v-bind:class="{
                  deactivated: numberoftickets <= minimumTickets,
                }"
                @click="decreaseNumberOfTickets($event)"
              >
                <div
                  class="horizontal-line"
                  v-bind:class="{
                    deactivated: numberoftickets <= minimumTickets,
                  }"
                />
              </v-btn>
            </v-col>
            <v-col class="col-2 mx-n4 Heading-Dark-H4-18-Center">
              {{ numberoftickets }}
            </v-col>
            <v-col class="col-1">
              <v-btn
                class="number-of-tickets-button"
                v-bind:class="{
                  deactivated: numberoftickets >= maximumTickets,
                }"
                @click="increaseNumberOfTickets($event)"
              >
                <div
                  class="horizontal-line ml-n1"
                  v-bind:class="{
                    deactivated: numberoftickets >= maximumTickets,
                  }"
                />
                <div
                  class="vertical-line"
                  v-bind:class="{
                    deactivated: numberoftickets >= maximumTickets,
                  }"
                />
              </v-btn>
            </v-col>
          </v-row>
          <div class="button-wrapper">
            <v-btn
              class="button gradient Heading-White-H5-16-Center"
              @click="seeOption()"
            >
              <span>
                {{ $t("tickets.continue") }}
              </span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import find from "lodash/find";
import { mapActions } from "vuex";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import OpenInMaps from "@/components/app/maps/OpenInMaps";
export default {
  name: "ticketdetail",
  components: {
    DatePicker,
    OpenInMaps,
  },
  async mounted() {
    // Prevent access to ticketdetails without login
    if (!this.$store.state.session.user && !this.$store.state.isMiKY) {
      if (this.$router.from.name !== "login") {
        this.$router.safePush({ name: "login" });
      } else {
        this.$router.back();
      }
    }
    await this.getTicket();
    if (this.tickettype === "reserve-out") {
      this.numberoftickets = 2;
      this.minimumTickets = 2;
      this.maximumTickets = 18;
    } else {
      this.maximumTickets = this.ticket.maxPerMember;
    }
  },
  data: () => ({
    showLocationDialog: false,
    isCalendar: false,
    nextdatesitemselected: 0,
    timeselected: 0,
    numberoftickets: 1,
    pickedDate: new Date(),
    minimumTickets: 1,
    maximumTickets: 0,
    ticket: null,
  }),
  computed: {
    tickettype() {
      return this.$route.params.type;
    },
    totalPrice() {
      return parseInt(this.numberoftickets) * parseFloat(this.ticket.price);
    },
    parentId() {
      return this.$router.currentRoute.params.restaurantId;
    },
    times() {
      let times = [];
      let start = Number(this.ticket.startTime[0]);
      // Adding 30 minute increments.
      let startMinute = Number(this.ticket.startTime[1]);
      let end =
        Number(this.ticket.endTime[0]) +
        (this.ticket.endTime[0] < this.ticket.startTime[0] ? 24 : 0);
      let endMinute = Number(this.ticket.endTime[1]);

      // Creating the list of possible times.
      for (let i = start; i <= end; i++) {
        // Just in case the time window passes midnight.
        let hour = i > 24 ? i - 24 : i;
        // This adds an item for every hour. It copies the minute from the startTime.
        times.push(
          (hour < 10 ? "0" : "") +
            hour +
            ":" +
            (startMinute < 10 ? "0" : "") +
            startMinute
        );
        // This adds a 30 minute increments.
        if (i <= end) {
          // Calculating the minute for this item.
          let nextMinute =
            startMinute + 30 >= 60 ? startMinute + 30 - 60 : startMinute + 30;
          // Calculating the hour for this item.
          hour += startMinute + 30 >= 60 ? 1 : 0;
          // Making sure the added item is not after the end time.
          if (hour > end || (hour == end && nextMinute > endMinute)) {
            return times;
          }

          // Finally, adding it to the times-list.
          times.push(
            (hour < 10 ? "0" : "") +
              hour +
              ":" +
              (nextMinute < 10 ? "0" : "") +
              nextMinute
          );
        }
      }
      return times;
    },
    availableDates() {
      let availableDates = [];
      this.ticket.dates.forEach((date) => {
        availableDates.push(this.getDate(date));
      });
      return availableDates;
    },
    selectedDate() {
      if (this.isCalendar) {
        return this.pickedDate;
      } else {
        return this.getDate(this.ticket.dates[this.nextdatesitemselected]);
      }
    },
    selectedTime() {
      return this.times[this.timeselected];
    },
    highResReserveoutImage() {
      let imageUrl = this.ticket.imageUrl;
      let imageSource = imageUrl.slice(0, 49);
      let imageName = imageUrl.split("/")[imageUrl.split("/").length - 1];
      let highResUrl = imageUrl;
      if (imageSource === "https://ro-images-reserveout.netdna-ssl.com/crop/") {
        highResUrl = imageSource + "1404x936/" + imageName;
      }
      return highResUrl;
    },
  },
  methods: {
    ...mapActions("favorites", ["toggleFavorite"]),
    find,
    async getTicket() {
      if (this.tickettype === "reserve-out") {
        await this.$router.app.$store.dispatch("recommendations/getDetails", {
          entryId: this.$route.params.restaurantId,
        });
        if (this.$store.state.recommendations.details.wowId) {
          await this.$router.app.$store.dispatch("reserveout/getPromotions", {
            restaurantId: this.$store.state.recommendations.details.wowId,
          });
        }
        let ticket = find(this.$store.state.reserveout.promotions, (o) => {
          // Adding the parents location to enable 'open in maps'
          o.location = this.$store.state.recommendations.details.location;
          return o.id === this.$route.params.ticketId;
        });
        if (ticket == undefined) {
          this.$router.safePush({
            name: "detail",
            params: {
              id: this.$route.params.restaurantId,
            },
          });
        }
        this.ticket = ticket;

        // Add an end time if there is none.
        if (!this.ticket.endTime) {
          this.ticket.endTime = [0, 0, 0, 0];
        }
      } else {
        // Convert pccm ticket to the reserve-out ticket.
        let ticket = this.$store.state.recommendations.tickets.find(
          (item) => item.id === this.$route.params.ticketId
        );
        if (!ticket) {
          ticket = this.$store.state.tickets.details;
        } else {
          ticket.name = ticket.header;
          ticket.placeName = this.$store.state.recommendations.details.header;
          ticket.venueAddress = {
            streetName: this.$store.state.recommendations.details.location
              .address,
          };
          ticket.location = this.$store.state.recommendations.details.location;
          ticket.summary = ticket.shortDescription;
          ticket.description = ticket.longDescription;

          // Add Next Dates
          if (ticket.recurringTicket) {
            let dateFrom = new Date(ticket.ticketAvailability.dateRange[0]);
            let dateTo = new Date(ticket.ticketAvailability.dateRange[1]);
            dateFrom =
              dateFrom.getDate() > new Date().getDate() ? dateFrom : new Date();

            let dates = [];
            for (let i = 0; i < 3; i++) {
              let current = new Date();
              current.setDate(dateFrom.getDate() + i);
              if (current.getDate() > dateTo.getDate()) {
                continue;
              }

              let mm = current.getMonth() + 1;
              let dd = current.getDate();
              dates.push([
                current.getFullYear(),
                (mm > 9 ? "" : "0") + mm,
                (dd > 9 ? "" : "0") + dd,
              ]);
            }
            ticket.dates = dates;
          } else {
            let dates = [];
            let date = ticket.ticketAvailability.specificDate.split("-");
            dates.push(date);
            ticket.dates = dates;
          }

          ticket.startTime = ticket.ticketAvailability.timeFrom.split(":");
          ticket.endTime = ticket.ticketAvailability.timeTo.split(":");
        }
        ticket.imageUrl =
          ticket.imageGallery && ticket.imageGallery.length > 0
            ? ticket.imageGallery[0].url
            : this.$store.state.recommendations.details.imageGallery[0];
        this.ticket = ticket;
      }
    },
    getDate(dateArray) {
      let date = new Date(
        Number(dateArray[0]),
        Number(dateArray[1]) - 1,
        Number(dateArray[2])
      );
      return date;
    },
    getWeekday(dateArray) {
      let date = this.getDate(dateArray);
      return date.toString().substring(0, 2);
    },
    getDay(dateArray) {
      return dateArray[2];
    },
    getMonth(dateArray) {
      let date = this.getDate(dateArray);
      return date.toString().substring(4, 7);
    },
    increaseNumberOfTickets(event) {
      if (this.numberoftickets < this.maximumTickets) {
        this.numberoftickets++;
      }
      event.preventDefault();
    },
    decreaseNumberOfTickets(event) {
      if (this.numberoftickets > this.minimumTickets) {
        this.numberoftickets--;
      }
      event.preventDefault();
    },
    seeOption() {
      if (this.tickettype === "reserve-out") {
        let options = {
          tickettype: this.tickettype,
          restaurantId: this.$store.state.recommendations.details.wowId,
          numberoftickets: this.numberoftickets,
          date: this.selectedDate,
          time: this.selectedTime,
          ticketId: this.$route.params.ticketId,
          placeName: this.ticket.placeName,
          countryId: this.$store.state.recommendations.details.countryId,
        };
        this.$store.commit("tickets/SET_TICKET_OPTIONS", options);
        this.$router.safePush({
          name: "ticketoptions",
        });
      } else {
        this.$store.dispatch("tickets/purchase", {
          ticket: { ...this.ticket, totalPrice: this.totalPrice },
        });
      }
    },
    share() {
      var options = {
        // message: this.item.header, // not supported on some apps (Facebook, Instagram)
        // subject: this.item.header, // fi. for email
        // files: [' ', ' '], // an array of filenames either locally or remotely
        url: `https://po-co.app/#/ticketdetail/${this.$route.params.restaurantId}/${this.$route.params.type}/${this.ticket.id}`,
        // chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
        // appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
        // iPadCoordinates: '0,0,200,200' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
      };
      var onSuccess = function (result) {
        console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
        console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
      };

      var onError = function (msg) {
        console.log("Sharing failed with message: " + msg);
      };
      try {
        window.plugins.socialsharing.shareWithOptions(
          options,
          onSuccess,
          onError
        );
      } catch (error) {
        // TODO: Add sharing in browser here.
        console.log("Sharing failed: " + error);
      }
    },
  },
};
</script>
<style scoped>
.background {
  background-image: linear-gradient(
    to bottom,
    var(--v-lightpurple-base),
    var(--v-lightpurple-lighten1)
  );
  min-height: 100vh;
}
.ticket {
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 13px;
  margin-bottom: 17px;
  padding-bottom: 14px;
  box-shadow: none !important;
  border-radius: 16px !important;
  background-color: rgba(0, 0, 0, 0) !important;
  filter: drop-shadow(1px 3px 5px var(--v-shadowonbackground-base));
  position: relative;
  color: black;
  font-size: 16px;
  background-size: 51% 100%;
  background-repeat: no-repeat;
  background-image: radial-gradient(
      circle at 0 calc(100% - 134px),
      rgba(255, 255, 255, 0) 10px,
      var(--v-background-base) 11px
    ),
    radial-gradient(
      circle at 100% calc(100% - 134px),
      rgba(255, 255, 255, 0) 10px,
      var(--v-background-base) 11px
    ) !important;
  background-position: top left, top right;
}
.ticket-tear-line {
  margin-top: 17px;
  border-top: 6px dotted var(--v-onbackground-darken1);
  margin-left: 15px;
  margin-right: 15px;
}
.thin-divider {
  margin-left: -1px;
  margin-right: -1px;
}
.button-wrapper {
  display: flex;
  justify-content: center;
  padding-right: 10px;
  margin-top: 12px;
}
.button {
  width: 140px !important;
  height: 36px !important;
  padding: 0px !important;
  margin: 0 0 6px 10px;
  object-fit: contain;
  border-radius: 16px;
  box-shadow: none;
}
.image {
  border-radius: 16px 16px 0 0 !important;
  box-shadow: 1px 3px 9px 0 rgba(0, 0, 0, 0.5);
}
.favorite {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 7px;
  padding-right: 6px;
}
.tabslider {
  height: 4px;
  background-image: linear-gradient(
    to right,
    var(--v-primary-base),
    var(--v-primary_gradient_middle-base) 50%,
    #606060 50%
  );
  border-radius: 2px;
  margin-left: 15px;
  margin-right: 15px;
}
.tabslider.calendar {
  height: 4px;
  background-image: linear-gradient(
    to left,
    var(--v-primary_gradient_middle-base),
    var(--v-primary-base) 50%,
    #606060 50%
  );
  border-radius: 2px;
  margin-left: 15px;
  margin-right: 15px;
}
.nextdates {
  height: 67px;
  padding: 0;
  margin: 16px 14px 12px 14px;
  border-radius: 12px;
  box-shadow: 1px 3px 9px 0 var(--v-shadowonbackground-base);
}
.nextdatesitem {
  background-color: #cacaca;
  border-radius: 12px 12px 0 0 !important;
}
.time {
  margin: 6px -7px 4px 16px;
  height: 32px !important;
  width: 73px;
  background-color: white !important;
  border-radius: 12px;
  border: 2px solid #9ea6aa;
  box-shadow: none;
  background-color: var(--v-background-base) !important;
}
.gradient {
  border: 0;
  border-radius: 12px;
  border: 2px solid #9ea6aa;
  box-shadow: none;
  background-color: var(--v-background-base) !important;
}
.gradient {
  border: 0;
  color: var(--v-onprimary-base) !important;
}
.number-of-tickets {
  margin: 0 0 0 18px;
}
.number-of-tickets-button {
  border-radius: 4.2px;
  border: 2px solid #9da6aa;
  box-shadow: none;
  min-width: 35px !important;
  height: 29px !important;
  padding: 0 !important;
  margin: -6px 0px 0 0px;
  background: var(--v-background-base) !important;
}
.horizontal-line {
  width: 10px;
  height: 1px;
  border: solid 1px #9da6aa;
}
.vertical-line {
  width: 1px;
  height: 10px;
  margin-left: -6px;
  border: solid 1px #9da6aa;
}
.deactivated {
  border-color: var(--v-background-darken1);
}
.datepicker {
  border: 0;
}
.darkdatepicker {
  border: 0;
  background-color: var(--v-background-base) !important;
}
</style>
