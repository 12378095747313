<template>
  <v-bottom-sheet
    :value="value"
    width="90%"
    overlay-color="backgroundoverlay"
    overlay-opacity="0.7"
  >
    <v-sheet style="background: transparent">
      <v-list style="border-radius: 10px">
        <v-list-item>
          <v-list-item-content @click="openGoogleMaps">
            <v-list-item-title class="Text-Dark-Text-1---18-Center">
              {{ $t("reservation.openInGoogleMaps") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="showIos">
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content @click="openMaps">
              <v-list-item-title class="Text-Dark-Text-1---18-Center">
                {{ $t("reservation.openInMaps") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-list style="border-radius: 10px" class="mt-2 mb-5">
        <v-list-item>
          <v-list-item-content @click="$emit('cancel')"
            ><v-list-item-title class="Text-Dark-Text-1---18-Center">
              {{ $t("reservation.cancel") }}</v-list-item-title
            ></v-list-item-content
          >
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { isIOS } from "mobile-device-detect";

export default {
  props: {
    lat: Number,
    lng: Number,
    value: Boolean,
  },
  methods: {
    openGoogleMaps() {
      let link =
        "https://maps.google.com/maps?daddr=" +
        this.lat +
        "," +
        this.lng +
        "&amp;ll=";
      if (
        this.$store.state.isMiKY &&
        (navigator.platform === "iPhone" || navigator.platform === "MacIntel")
      ) {
        window.location.assign(link);
      } else {
        window.open(link, "_system");
      }
      this.location = false;
    },
    openMaps() {
      window.location.href = encodeURI(
        "maps://maps.apple.com/maps?daddr=" +
          this.lat +
          "," +
          this.lng +
          "&amp;ll="
      );
      this.location = false;
    },
  },
  computed: {
    showIos() {
      return isIOS;
    },
  },
};
</script>
